import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  actions: {
    fetch ({ commit, dispatch }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (isPreload) {
          commit('TOGGLE_PRELOADER', true, { root: true })
        }
        apiClient().get('events').finally(() => {
          if (isPreload) {
            commit('TOGGLE_PRELOADER', false, { root: true })
          }
        }).then(({ data }) => {
          resolve(data.data)
        }).catch(error => {
          dispatch('checkUnauthenticated', error, { root: true })
          reject(error)
        })
      })
    }
  }
}
