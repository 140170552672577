import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    recreationTypes: []
  },

  mutations: {
    FETCH_TYPES (state, types) {
      state.recreationTypes = types
    }
  },

  actions: {
    fetch ({ commit, state, dispatch }) {
      if (!state.recreationTypes.length) {
        apiClient().post('recreation-types').then(({ data }) => {
          commit('FETCH_TYPES', data.data)
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      }
    }
  }
}
