import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
  },

  mutations: {
  },

  actions: {
    fetch ({ commit, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        if (typeof payload.isPreload === 'undefined') {
          commit('TOGGLE_PRELOADER', true, { root: true })
        }
        apiClient().get('beauty-salons/services', {
          params: payload.filter
        }).finally(() => {
          if (typeof payload.isPreload === 'undefined') {
            commit('TOGGLE_PRELOADER', false, { root: true })
          }
        }).then(({ data }) => {
          resolve(data.data)
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
          reject(error)
        })
      })
    }
  }
}
