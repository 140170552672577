import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    restaurants: []
  },

  mutations: {
    FETCH_RESTAURANTS (state, payload) {
      state.restaurants = payload
    }
  },

  actions: {
    fetch ({ commit, state, dispatch }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.restaurants.length) {
          resolve(state.restaurants)
        } else {
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('restaurants').finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).then(({ data }) => {
            commit('FETCH_RESTAURANTS', data.data)
            resolve(data.data)
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    }
  }
}
