export default {
  namespaced: true,

  state: {
    isShowModalApp: false,
    titleModalApp: '',
    textModalApp: ''
  },

  mutations: {
    TOGGLE_MODAL (state, payload) {
      state.isShowModalApp = payload
    },
    SET_TITLE_MODAL (state, payload) {
      state.titleModalApp = payload
    },
    SET_TEXT_MODAL (state, payload) {
      state.textModalApp = payload
    }
  },
  actions: {
    show ({ commit }, payload) {
      const title = typeof payload.title !== 'undefined' ? payload.title : 'Ошибка!'
      let message = payload.text
      if (typeof payload.text === 'object') {
        message = (typeof payload.text.response.data !== 'undefined') ? payload.text.response.data.message : ''
      }
      commit('SET_TITLE_MODAL', title)
      commit('SET_TEXT_MODAL', message)
      commit('TOGGLE_MODAL', true)
    },
    hide ({ commit }) {
      commit('TOGGLE_MODAL', false)
      commit('SET_TITLE_MODAL', '')
      commit('SET_TEXT_MODAL', '')
    }
  }
}
