export default {
  namespaced: true,

  state: {
    services: [],
    dishes: [],
    excursions: [],
    spa: [],
    beauty: []
  },

  getters: {
    GET_AMOUNT_DISHES (state) {
      const total = 0
      return state.dishes.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), total)
    },
    GET_AMOUNT_SERVICES (state) {
      return state.services.reduce((accumulatorService, currentService) => {
        let sum = 0
        if (currentService.prices) {
          sum = currentService.prices.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value * currentValue.quantity
          }, 0)
        }
        return accumulatorService + sum
      }, 0)
    },
    GET_AMOUNT_EXCURSIONS (state) {
      const total = 0
      return state.excursions.reduce((accumulator, currentValue) => accumulator + (currentValue.price * currentValue.quantity), total)
    },
    GET_AMOUNT_SPA (state) {
      return state.spa.reduce((accumulatorService, currentService) => {
        let sum = 0
        if (currentService.prices) {
          sum = currentService.prices.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value * currentValue.quantity
          }, 0)
        }
        return accumulatorService + sum
      }, 0)
    },
    GET_AMOUNT_BEAUTY (state) {
      return state.beauty.reduce((accumulatorService, currentService) => {
        let sum = 0
        if (currentService.prices) {
          sum = currentService.prices.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value * currentValue.quantity
          }, 0)
        }
        return accumulatorService + sum
      }, 0)
    },
    GET_ALL_TIME_PREPARING (state) {
      const total = [0, 0]
      state.dishes.forEach((item) => {
        const currentTime = item.time_preparing.split(':')
        total[0] = total[0] + Number(currentTime[0])
        total[1] = total[1] + Number(currentTime[1])
      })
      return total.join(':')
    },
    GET_COUNT_ALL (state) {
      return state.dishes.length +
        state.services.length +
        state.excursions.length +
        state.spa.length +
        state.beauty.length
    }
  },

  mutations: {
    ADD_DISH (state, payload) {
      if (!state.dishes.find(item => payload.id === item.id)) {
        const dish = {
          id: payload.id,
          name: payload.name,
          price: payload.price,
          weight: payload.weight,
          composition: payload.composition,
          thumb: payload.media ? payload.media.file : false,
          time_preparing: payload.time_preparing,
          quantity: 1
        }
        state.dishes.push(dish)
      }
    },
    REMOVE_DISH (state, dish) {
      state.dishes = Array.from(state.dishes.filter(item => item.id !== dish.id))
    },
    SET_DISH_QUANTITY (state, { id, quantity }) {
      state.dishes = state.dishes.map((item) => {
        if (item.id === id) {
          item.quantity = quantity
        }
        return item
      })
    },
    CLEAR_DISH (state) {
      state.dishes = []
    },
    CLEAR_SERVICE (state) {
      state.services = []
    },
    CLEAR_EXCURSION (state) {
      state.excursions = []
    },
    CLEAR_SPA (state) {
      state.spa = []
    },
    CLEAR_BEAUTY (state) {
      state.beauty = []
    },
    ADD_SERVICE (state, payload) {
      if (!state.services.find(item => payload.id === item.id)) {
        const service = {
          id: payload.id,
          name: payload.name,
          time_preparing: payload.time_preparing ? payload.time_preparing : null,
          prices: [],
          intro: payload.intro,
          thumb: payload.media ? payload.media.file : false,
          type: payload.type,
          price_value: payload.price_value,
          is_complex: payload.is_complex,
          hotel_id: payload.hotel_id
        }
        if (payload.prices && payload.prices.length === 1) {
          service.prices = payload.prices
          service.prices[0].quantity = typeof service.prices[0].quantity !== 'undefined' ? service.prices[0].quantity : 1
        } else if (payload.prices && payload.prices.length > 1) {
          service.prices = payload.prices.filter(item => item.quantity > 0)
        }
        state.services.push(service)
      }
    },
    REMOVE_SERVICE (state, service) {
      state.services = Array.from(state.services.filter(item => item.id !== service.id))
    },
    SET_SERVICE_QUANTITY (state, { id, index, quantity }) {
      const serviceIndex = state.services.findIndex(item => item.id === id)
      state.services[serviceIndex].prices[index].quantity = quantity
    },
    ADD_EXCURSION (state, payload) {
      if (!state.excursions.find(item => payload.id === item.id)) {
        const excursion = {
          id: payload.id,
          name: payload.name,
          price: payload.price,
          intro: payload.intro,
          thumb: payload.media ? payload.media.file : false,
          price_value: payload.price_value,
          price_note: payload.price_note,
          quantity: 1
        }
        state.excursions.push(excursion)
      }
    },
    REMOVE_EXCURSION (state, excursion) {
      state.excursions = Array.from(state.excursions.filter(item => item.id !== excursion.id))
    },
    SET_EXCURSION_QUANTITY (state, { id, quantity }) {
      state.excursions = state.excursions.map((item) => {
        if (item.id === id) {
          item.quantity = quantity
        }
        return item
      })
    },
    ADD_SPA (state, payload) {
      if (!state.spa.find(item => payload.id === item.id)) {
        const service = {
          id: payload.id,
          name: payload.name,
          prices: payload.prices,
          intro: payload.intro,
          thumb: payload.media ? payload.media.file : false,
          price_value: payload.price_value,
          spa_salon_id: payload.spa_salon_id
        }
        if (typeof service.prices[0].quantity === 'undefined') {
          service.prices[0].quantity = 1
        }
        state.spa.push(service)
      }
    },
    REMOVE_SPA (state, payload) {
      state.spa = Array.from(state.spa.filter(item => item.id !== payload.id))
    },
    SET_SPA_QUANTITY (state, { id, index, quantity }) {
      const serviceIndex = state.spa.findIndex(item => item.id === id)
      state.spa[serviceIndex].prices[index].quantity = quantity
    },
    ADD_BEAUTY (state, payload) {
      if (!state.beauty.find(item => payload.id === item.id)) {
        const service = {
          id: payload.id,
          name: payload.name,
          prices: payload.prices.filter(item => item.quantity > 0),
          intro: payload.intro,
          thumb: payload.media ? payload.media.file : false,
          price_value: payload.price_value,
          beauty_salon_id: payload.beauty_salon_id
        }
        if (typeof service.prices[0].quantity === 'undefined') {
          service.prices[0].quantity = 1
        }
        state.beauty.push(service)
      }
    },
    REMOVE_BEAUTY (state, service) {
      state.beauty = Array.from(state.beauty.filter(item => item.id !== service.id))
    },
    SET_BEAUTY_QUANTITY (state, { id, index, quantity }) {
      const serviceIndex = state.beauty.findIndex(item => item.id === id)
      state.beauty[serviceIndex].prices[index].quantity = quantity
    }
  },

  actions: {
  }
}
