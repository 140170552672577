import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import router from '@/router'
import auth from './modules/auth'
import modal from './modules/modal'
import client from './modules/client'
import basket from './modules/basket'
import dish from './modules/dish'
import category from './modules/category'
import order from './modules/order'
import recreation from './modules/recreation'
import hotel from './modules/hotel'
import service from './modules/service'
import excursion from './modules/excursion'
import spaSalon from './modules/spaSalon'
import spaService from './modules/spaService'
import beautyCategory from './modules/beautyCategory'
import beautyService from './modules/beautyService'
import restaurant from './modules/restaurant'
import restaurantService from './modules/restaurantService'
import event from './modules/event'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

export default createStore({
  plugins: [
    createPersistedState({
      paths: ['auth', 'basket']
    })
  ],
  state: {
    isPreloader: false,
    isShowMenu: false
  },
  getters: {
  },
  mutations: {
    TOGGLE_PRELOADER (state, payload) {
      state.isPreloader = payload
    },
    TOGGLE_SHOW_MENU (state, payload) {
      state.isShowMenu = payload
    }
  },
  actions: {
    checkUnauthenticated ({ dispatch, commit }, error) {
      const message = (typeof error.response.data !== 'undefined') ? error.response.data.message : ''
      if (message === 'Unauthenticated.' && error.response.status === 401) {
        const mobileHash = cookies.get('mobile-authentication_hash')
        const userHash = cookies.get('mobile-authentication_user')
        if (mobileHash && userHash) {
          dispatch('auth/loginMobile', { mobileHash, userHash })
          return
        }
        commit('auth/LOGOUT')
        commit('auth/CHANGE_ACTION', 'AuthorizationForm')
        router.push({ name: 'Login' })
      } else {
        dispatch('modal/show', { text: message })
      }
    },
    showMenu ({ commit }, action = false) {
      commit('TOGGLE_SHOW_MENU', action)
      if (action) {
        document.body.classList.add('lock')
      } else {
        document.body.classList.remove('lock')
      }
    }
  },
  modules: {
    auth,
    client,
    dish,
    basket,
    category,
    order,
    recreation,
    hotel,
    service,
    excursion,
    spaSalon,
    spaService,
    beautyCategory,
    beautyService,
    restaurant,
    restaurantService,
    event,
    modal
  }
})
