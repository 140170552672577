import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  actions: {
    fetch ({ commit, state, dispatch }, payload = { filter: {}, isPreload: true }) {
      if (payload.isPreload) {
        commit('TOGGLE_PRELOADER', true, { root: true })
      }
      return new Promise((resolve, reject) => {
        apiClient().get('services', { params: payload.filter }).finally(() => {
          if (payload.isPreload) {
            commit('TOGGLE_PRELOADER', false, { root: true })
          }
        }).then(({ data }) => {
          resolve(data.data)
        }).catch(error => {
          dispatch('checkUnauthenticated', error, { root: true })
          reject(error)
        })
      })
    }
  }
}
