import Api from '../../api/api'
import router from '@/router'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

export default {
  namespaced: true,

  state: {
    action: 'AuthorizationForm',
    isModalExtraData: false,
    isLoggedIn: false,
    dateLogin: Date,
    user: {}
  },

  getters: {
    IS_RESERVATION (state) {
      return !!state.user.hotel_id
    }
  },

  mutations: {
    LOGIN (state) {
      state.isLoggedIn = true
    },
    LOGOUT (state) {
      state.isLoggedIn = false
      state.user = {}
    },
    FETCH_USER (state, user) {
      state.user = user
      state.dateLogin = new Date()
    },
    CHANGE_ACTION (state, payload) {
      state.action = payload
    },
    TOGGLE_MODAL_EXTRA_DATA (state, payload) {
      state.isModalExtraData = payload
    }
  },

  actions: {
    async login ({ commit }, auth) {
      await Api().get('/csrf-cookie')
      return new Promise((resolve, reject) => {
        setMobileHash(auth)
        Api().post('/login', auth).then(({ data }) => {
          if (typeof data.data !== 'undefined') {
            commit('LOGIN')
            commit('FETCH_USER', data.data)
            router.push({ name: 'Home' }).then(() => {
              if (data.data.hotel_id) {
                commit('TOGGLE_MODAL_EXTRA_DATA', true)
              }
            })
          }
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    async loginMobile ({ commit }, authMobile) {
      if (!checkCsrfCookie()) {
        await Api().get('/csrf-cookie')
      }
      return Api().post('/login-mobile', authMobile).then(({ data }) => {
        commit('LOGIN')
        commit('FETCH_USER', data.data)
        if (data.data.hotel_id) {
          commit('TOGGLE_MODAL_EXTRA_DATA', true)
        }
      }).catch(() => {
        commit('LOGOUT')
        commit('CHANGE_ACTION', 'AuthorizationForm')
        cookies.remove('mobile-authentication_user')
        router.push({ name: 'Login' })
      })
    },

    registration ({ commit }, auth) {
      return new Promise((resolve, reject) => {
        Api().post('/registration', auth).then(({ data }) => {
          if (typeof data.data !== 'undefined') {
            commit('FETCH_USER', data.data)
            commit('CHANGE_ACTION', 'CompleteRegistrationForm')
          }
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    completeRegistration ({ commit }, dataRegistration) {
      setMobileHash(dataRegistration)
      return new Promise((resolve, reject) => {
        Api().post('/complete-registration', dataRegistration).then(({ data }) => {
          if (typeof data.data !== 'undefined') {
            commit('LOGIN')
            commit('FETCH_USER', data.data)
            router.push({ name: 'Home' })
          }
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    completeReservation ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        if (state.user.recreation_types_id !== payload.recreation_types_id ||
          state.user.name !== payload.name ||
          state.user.email !== payload.email
        ) {
          Api().put('/complete-reservation/' + state.user.id, payload).then(({ data }) => {
            commit('FETCH_USER', data.data)
            resolve(data)
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        } else {
          resolve()
        }
      })
    },

    fetch ({ commit, dispatch }) {
      Api().get('/user').then(({ data }) => {
        commit('FETCH_USER', data.data)
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },

    // eslint-disable-next-line no-empty-pattern
    async sendSMSCode ({}, phone) {
      if (!checkCsrfCookie()) {
        await Api().get('/csrf-cookie')
      }
      return new Promise((resolve, reject) => {
        Api().post('/sms-code/send', phone).then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        })
      })
    },

    async logout ({ commit, dispatch }) {
      if (!checkCsrfCookie()) {
        await Api().get('/csrf-cookie')
      }
      await Api().post('/logout').catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
      commit('LOGOUT')
      commit('CHANGE_ACTION', 'AuthorizationForm')
      cookies.remove('mobile-authentication_user')
      router.push({ name: 'Login' })
    },

    deleteProfile ({ commit, dispatch }) {
      Api().delete('/delete-profile').then(() => {
        commit('LOGOUT')
        commit('CHANGE_ACTION', 'AuthorizationForm')
        cookies.remove('mobile-authentication_user')
        router.push({ name: 'Login' })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    }
  }
}

function checkCsrfCookie () {
  return cookies.isKey('XSRF-TOKEN')
}

function setMobileHash (data) {
  const mobileHash = cookies.get('mobile-authentication_hash')
  if (mobileHash) {
    data.mobileHash = mobileHash
  }
}
