<template>
  <router-view/>
  <transition name="modal">
    <base-modal
      :title="titleModalApp"
      v-if="isShowModalApp"
      @closeModalAction="hideModal"
    >
      {{ textModalApp }}
      <div class="modal__footer">
        <base-button
          small
          title="Ок"
          @click="hideModal"
        />
      </div>
    </base-modal>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseModal from '@/components/BaseModal'
import BaseButton from '@/components/ui/BaseButton'

export default {
  components: {
    BaseButton,
    BaseModal
  },
  computed: {
    ...mapState({
      isShowModalApp: state => state.modal.isShowModalApp,
      titleModalApp: state => state.modal.titleModalApp,
      textModalApp: state => state.modal.textModalApp
    })
  },
  methods: {
    ...mapActions({
      hideModal: 'modal/hide'
    })
  }
}
</script>

<style lang="scss">
  @import "assets/scss/app";
</style>
