import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {},

  mutations: {
  },

  actions: {
    getDishes ({ dispatch }, filter) {
      return new Promise((resolve) => {
        apiClient().post('room-service/dishes', filter).then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    getDishesById ({ dispatch }, ids) {
      return new Promise((resolve) => {
        apiClient().post('room-service/dishes/ids', { ids }).then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    }
  }
}
