import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    hotels: []
  },

  mutations: {
    FETCH_HOTELS (state, payload) {
      state.hotels = payload
    }
  },

  actions: {
    fetch ({ commit, state, dispatch }, isPreload = false) {
      return new Promise((resolve, reject) => {
        if (isPreload) {
          commit('TOGGLE_PRELOADER', true, { root: true })
        }
        if (state.hotels.length) {
          resolve(state.hotels)
        } else {
          apiClient().get('hotels').then(({ data }) => {
            commit('FETCH_HOTELS', data.data)
            resolve(data.data)
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch(error => {
            // dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    }
  }
}
