import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    categories: []
  },

  mutations: {
    FETCH_CATEGORIES (state, payload) {
      state.categories = payload
    }
  },

  actions: {
    fetch ({ commit, state, dispatch, rootState }) {
      if (!state.categories.length) {
        commit('TOGGLE_PRELOADER', true, { root: true })
        const filter = {
          hotel_id: rootState.auth.user.hotel_id
        }
        apiClient().post('room-service/category', filter).then(({ data }) => {
          commit('FETCH_CATEGORIES', data.data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      }
    }
  }
}
