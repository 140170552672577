import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    categories: []
  },

  mutations: {
    FETCH_CATEGORIES (state, payload) {
      state.categories = payload
    }
  },

  actions: {
    fetch ({ commit, state, dispatch }, payload = {}) {
      return new Promise((resolve, reject) => {
        if (state.categories.length) {
          resolve()
        } else {
          if (typeof payload.isPreload === 'undefined') {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('beauty-salons/categories').then(({ data }) => {
            commit('FETCH_CATEGORIES', data.data)
            resolve()
          }).finally(() => {
            if (typeof payload.isPreload === 'undefined') {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    }
  }
}
