import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    salons: []
  },

  mutations: {
    FETCH_SALONS (state, payload) {
      state.salons = payload
    }
  },

  actions: {
    fetch ({ commit, state, dispatch }) {
      if (!state.salons.length) {
        commit('TOGGLE_PRELOADER', true, { root: true })
        apiClient().get('spa/salons').then(({ data }) => {
          commit('FETCH_SALONS', data.data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      }
    }
  }
}
