<template>
  <div class="modal">
    <div class="modal__wrapper" @click="clickOutside">
      <div class="modal__container" ref="modalContainer">

        <div class="modal__header">
          <div>{{ title }}</div>
          <button class="modal__close close-pic" @click="$emit('closeModalAction')" />
        </div>
        <div class="modal__body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    isClickOutside: {
      type: Boolean,
      default: true
    }
  },
  emits: ['closeModalAction'],
  methods: {
    clickOutside (e) {
      const withinBoundaries = e.composedPath().includes(this.$refs.modalContainer)
      if (!withinBoundaries && this.isClickOutside) {
        this.$emit('closeModalAction')
      }
    }
  }
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.5s ease;
  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  &__container {
    width: 340px;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  }
  &__header {
    margin: 0;
    padding: 1rem;
    background-color: #0c352c;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
  &__body {
    padding: 1rem;
  }
  &__footer {
    text-align: center;
    margin: 1rem 0 0 0;
  }
}

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-text-enter-from, .modal-text-leave-to {
  opacity: 0;
}
.modal-text {
  &__wrapper {
    max-height: 330px;
    overflow-y: scroll;
    line-height: 1.5;
  }

  @include media-tablet {
    .modal__container {
      width: 580px;
    }
  }
}
</style>
