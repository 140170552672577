import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
    dishOrders: [],
    serviceOrders: [],
    excursionOrders: [],
    spaOrders: [],
    beautyOrders: [],
    restaurantOrders: [],
    eventOrders: []
  },

  mutations: {
    FETCH_DISH_ORDERS (state, payload) {
      state.dishOrders = payload
    },
    FETCH_SERVICE_ORDERS (state, payload) {
      state.serviceOrders = payload
    },
    FETCH_EXCURSION_ORDERS (state, payload) {
      state.excursionOrders = payload
    },
    FETCH_SPA_ORDERS (state, payload) {
      state.spaOrders = payload
    },
    FETCH_BEAUTY_ORDERS (state, payload) {
      state.beautyOrders = payload
    },
    FETCH_RESTAURANT_ORDERS (state, payload) {
      state.restaurantOrders = payload
    },
    FETCH_EVENT_ORDERS (state, payload) {
      state.eventOrders = payload
    }
  },

  actions: {
    getDishOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      if (!state.dishOrders.length) {
        const filter = {
          client_id: rootState.auth.user.client_id
        }
        if (isPreload) {
          commit('TOGGLE_PRELOADER', true, { root: true })
        }
        apiClient().post('/room-service/order', filter).then(({ data }) => {
          commit('FETCH_DISH_ORDERS', data.data)
        }).finally(() => {
          if (isPreload) {
            commit('TOGGLE_PRELOADER', false, { root: true })
          }
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      }
    },
    getDishCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().post('/room-service/order/completed', filter).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendDishOrder ({ dispatch, commit, rootState }) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const order = {
        dishes_list: rootState.basket.dishes.map(item => ({ id: item.id, quantity: item.quantity })),
        client_id: rootState.auth.user.client_id,
        hotel_id: rootState.auth.user.hotel_id
      }
      apiClient().post('room-service/order/add', order).then(({ data }) => {
        commit('basket/CLEAR_DISH', null, { root: true })
        commit('FETCH_DISH_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Заказ успешно сформирован!',
          text: 'В ближайшее время с вами свяжутся для уточнения заказа.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getServiceOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.serviceOrders.length) {
          resolve(state.serviceOrders)
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().post('/services/orders', filter).then(({ data }) => {
            commit('FETCH_SERVICE_ORDERS', data.data)
            resolve(data.data)
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', true, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    getServiceCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().post('/services/orders/completed', filter).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendServiceOrder ({ dispatch, commit, rootState }) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const services = rootState.basket.services.map((service) => {
        return {
          id: service.id,
          is_complex: service.is_complex,
          type: service.type,
          prices: JSON.stringify(service.prices.filter(item => item.quantity > 0)),
          hotel_id: service.hotel_id,
          time_preparing: service.time_preparing
        }
      })
      const data = {
        services,
        client_id: rootState.auth.user.client_id
      }
      apiClient().post('services/orders/add', data).then(({ data }) => {
        commit('basket/CLEAR_SERVICE', null, { root: true })
        commit('FETCH_SERVICE_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Услуги успешно заказаны!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по заказанным услугам.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getExcursionOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.excursionOrders.length) {
          resolve(state.excursionOrders)
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('/excursions/orders', { params: filter }).then(({ data }) => {
            commit('FETCH_EXCURSION_ORDERS', data.data)
            resolve(data.data)
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    sendExcursionOrder ({ dispatch, commit, rootState }) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const excursions = rootState.basket.excursions.map((excursion) => {
        return {
          id: excursion.id,
          quantity: excursion.quantity
        }
      })
      const data = {
        excursions,
        client_id: rootState.auth.user.client_id,
        hotel_id: rootState.auth.user.hotel_id
      }
      apiClient().post('excursions/orders/add', data).then(({ data }) => {
        commit('basket/CLEAR_EXCURSION', null, { root: true })
        commit('FETCH_EXCURSION_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Экскурсии успешно заказаны!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по заказанным экскурсиям.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getExcursionCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().get('/excursions/orders/completed', { params: filter }).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    getSpaOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.spaOrders.length) {
          resolve()
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('/spa/orders', { params: filter }).then(({ data }) => {
            commit('FETCH_SPA_ORDERS', data.data)
            resolve()
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    getSpaCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().get('/spa/orders/completed',
          { params: filter }).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendSpaOrder ({ dispatch, commit, rootState }) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const spa = rootState.basket.spa.map((item) => {
        return {
          id: item.id,
          prices: JSON.stringify(item.prices),
          spa_salon_id: item.spa_salon_id
        }
      })
      const data = {
        spa,
        client_id: rootState.auth.user.client_id
      }
      apiClient().post('spa/orders/add', data).then(({ data }) => {
        commit('basket/CLEAR_SPA', null, { root: true })
        commit('FETCH_SPA_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Спа услуги успешно заказаны!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по заказанным услугам.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getBeautyOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.beautyOrders.length) {
          resolve()
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('/beauty-salons/orders', { params: filter }).then(({ data }) => {
            commit('FETCH_BEAUTY_ORDERS', data.data)
            resolve()
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    getBeautyCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().get('/beauty-salons/orders/completed',
          { params: filter }).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendBeautyOrder ({ dispatch, commit, rootState }) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const beauty = rootState.basket.beauty.map((item) => {
        return {
          id: item.id,
          prices: JSON.stringify(item.prices.filter(item => item.quantity > 0))
        }
      })
      const data = {
        beauty,
        client_id: rootState.auth.user.client_id
      }
      apiClient().post('beauty-salons/orders/add', data).then(({ data }) => {
        commit('basket/CLEAR_BEAUTY', null, { root: true })
        commit('FETCH_BEAUTY_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Услуги успешно заказаны!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по заказанным услугам.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getRestaurantOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.restaurantOrders.length) {
          resolve(state.restaurantOrders)
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('/restaurants/orders', { params: filter }).then(({ data }) => {
            commit('FETCH_RESTAURANT_ORDERS', data.data)
            resolve(data.data)
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    getRestaurantCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().get('/restaurants/orders/completed',
          { params: filter }).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendRestaurantOrder ({ dispatch, commit, rootState }, payload) {
      commit('TOGGLE_PRELOADER', true, { root: true })
      const restaurant = {
        restaurant_service_id: payload.id,
        restaurant_id: payload.restaurant_id,
        client_id: rootState.auth.user.client_id,
        price: payload.price
      }
      apiClient().post('restaurants/orders/add', restaurant).then(({ data }) => {
        commit('FETCH_RESTAURANT_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Заявка отправлена!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по заказу.'
        }, { root: true })
      }).finally(() => {
        commit('TOGGLE_PRELOADER', false, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    },
    getEventOrders ({ dispatch, commit, state, rootState }, isPreload = true) {
      return new Promise((resolve, reject) => {
        if (state.eventOrders.length) {
          resolve(state.eventOrders)
        } else {
          const filter = {
            client_id: rootState.auth.user.client_id
          }
          if (isPreload) {
            commit('TOGGLE_PRELOADER', true, { root: true })
          }
          apiClient().get('/events/orders', { params: filter }).then(({ data }) => {
            commit('FETCH_EVENT_ORDERS', data.data)
            resolve(data.data)
          }).finally(() => {
            if (isPreload) {
              commit('TOGGLE_PRELOADER', false, { root: true })
            }
          }).catch((error) => {
            dispatch('checkUnauthenticated', error, { root: true })
            reject(error)
          })
        }
      })
    },
    getEventCompletedOrders ({ dispatch, commit, rootState }, page = 1) {
      const filter = {
        client_id: rootState.auth.user.client_id,
        page
      }
      commit('TOGGLE_PRELOADER', true, { root: true })
      return new Promise((resolve) => {
        apiClient().get('/events/orders/completed',
          { params: filter }).then(({ data }) => {
          resolve(data)
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).catch(error => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    },
    sendEventOrder ({ dispatch, commit, rootState }, payload) {
      const event = {
        hotel_id: payload.hotel_id,
        event_id: payload.id,
        client_id: rootState.auth.user.client_id,
        price: payload.price
      }
      apiClient().post('events/orders/add', event).then(({ data }) => {
        commit('FETCH_EVENT_ORDERS', data.data)
        dispatch('modal/show', {
          title: 'Заявка отправлена!',
          text: 'В ближайшее время с вами свяжутся для уточнения деталей по бронированию.'
        }, { root: true })
      }).catch((error) => {
        dispatch('checkUnauthenticated', error, { root: true })
      })
    }
  }
}
