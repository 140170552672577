<template>
  <button v-if="!routerName" class="button" :class="className" :disabled="disabled" @click.prevent="$emit('click')">
    <span v-if="title.length">{{ title }}</span>
    <slot />
    <span v-if="loader !== null" class="circularG-wrap">
      <span class="circularG circularG_1" />
      <span class="circularG circularG_2" />
      <span class="circularG circularG_3" />
      <span class="circularG circularG_4" />
      <span class="circularG circularG_5" />
      <span class="circularG circularG_6" />
      <span class="circularG circularG_7" />
      <span class="circularG circularG_8" />
    </span>
  </button>
  <router-link v-else :to="{name: routerName}" class="button" :class="className" :disabled="disabled">
    <span>
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    title: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    },
    routerName: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  computed: {
    className () {
      return {
        button_error: this.error,
        button_loader: this.loader,
        button_green: this.green,
        button_small: this.small
      }
    }
  }
}
</script>

<style lang="scss">
@import '/src/assets/scss/_loader.scss';

.button {
  // font-family: "Roboto", sans-serif;
  display: inline-block;
  text-align: center;
  background: #c09e5f;
  padding: 0.8rem 1.2rem;
  transition: all .2s linear;
  outline: none;
  justify-content: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  color: #fff;
  width: 100%;

  &_green {
    background: #3cd52e;
  }

  &_small {
    padding: 0.5rem 1.2rem;
  }

  &_error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
    pointer-events: none;
  }

  &_loader {
    opacity: 0.7;
    pointer-events: none;
    .circularG-wrap {
      visibility: visible;
    }
  }

  &_icon {
    display: flex;
    align-items: center;

    > span {
      display: block;
      margin-right: 6px;
    }
  }

  .button_green:hover {
    background: #ff5000;
  }

  &:hover {
    background: #7e612a;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  @include media-tablet {
    width: auto;
    font-size: 1.2rem;
    padding: 0.8rem 3rem;

    &_small {
      padding: 0.5rem 2rem;
    }
  }
}

.button-link {
  background: #c09e5f;
  position: relative;
  width: 42px;
  height: 42px;

  @include media-desktop {
    width: 53px;
    height: 53px;

    &::after {
      top: 14px;
      left: 15px;
    }
  }
}

.circularG-wrap {
  position: absolute;
  right: -7px;
  top: -5px;
  visibility: hidden;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
