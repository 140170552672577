import { createRouter, createWebHistory } from 'vue-router'
// import { useCookies } from 'vue3-cookies'
import store from '../store'

// const { cookies } = useCookies()

const routes = [
  {
    path: '/',
    name: 'Base',
    component: () => import('@/components/layouts/BaseLayout.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/pages/ProfilePage.vue')
      },
      {
        path: '/room-service/category',
        name: 'RoomService',
        component: () => import('@/pages/RoomServicePage.vue')
      },
      {
        path: '/room-service/category/:id',
        name: 'DishesCategory',
        props: true,
        component: () => import('@/pages/DishesCategory.vue')
      },
      {
        path: '/basket',
        name: 'Basket',
        component: () => import('@/pages/BasketPage.vue')
      },
      {
        path: '/dish-orders',
        name: 'DishOrders',
        component: () => import('@/pages/DishOrders.vue')
      },
      {
        path: '/service-orders',
        name: 'ServiceOrders',
        component: () => import('@/pages/ServiceOrders.vue')
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/pages/PrivacyPolicy.vue')
      },
      {
        path: '/excursions',
        name: 'Excursions',
        component: () => import('@/pages/excursions/ExcursionsPage.vue')
      },
      {
        path: '/reception',
        name: 'Reception',
        component: () => import('@/pages/ReceptionPage.vue')
      },
      {
        path: '/reviews',
        name: 'Reviews',
        component: () => import('@/pages/ReviewsPage.vue')
      },
      {
        path: '/excursion-orders',
        name: 'ExcursionOrders',
        component: () => import('@/pages/excursions/ExcursionOrders.vue')
      },
      {
        path: '/spa-salons',
        name: 'SpaSalons',
        component: () => import('@/pages/SpaSalons.vue')
      },
      {
        path: '/spa-salons/:id',
        name: 'SpaServices',
        props: true,
        component: () => import('@/pages/SpaServices.vue')
      },
      {
        path: '/spa-orders',
        name: 'SpaOrders',
        component: () => import('@/pages/SpaOrders.vue')
      },
      {
        path: '/beauty-salons/categories',
        name: 'BeautySalonCategories',
        component: () => import('@/pages/beauty/BeautySalonCategories.vue')
      },
      {
        path: '/beauty-salons/categories/:id',
        name: 'BeautySalonServices',
        props: true,
        component: () => import('@/pages/beauty/BeautySalonServices.vue')
      },
      {
        path: '/beauty-orders',
        name: 'BeautyOrders',
        component: () => import('@/pages/beauty/BeautyOrders.vue')
      },
      {
        path: '/restaurants',
        name: 'Restaurants',
        component: () => import('@/pages/restaurants/RestaurantsPage.vue')
      },
      {
        path: '/restaurants/:id',
        name: 'RestaurantServices',
        props: true,
        component: () => import('@/pages/restaurants/RestaurantServices.vue')
      },
      {
        path: '/restaurant-orders',
        name: 'RestaurantOrders',
        component: () => import('@/pages/restaurants/RestaurantOrders.vue')
      },
      {
        path: '/events',
        name: 'EventsPage',
        component: () => import('@/pages/events/EventsPage.vue')
      },
      {
        path: '/events-orders',
        name: 'EventOrders',
        component: () => import('@/pages/events/EventOrders.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/LoginPage.vue'),
    meta: {
      title: 'Авторизация',
      middleware: 'guest'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`
  // const auntMobile = cookies.get('mobile-authentication_hash')
  // console.log(auntMobile)
  // cookies.set('mobile-authentication_hash', '123456789')
  if (to.name === 'Base') {
    next({ name: 'Home' })
  } else if (typeof to.meta.middleware === 'undefined') {
    if (store.state.auth.isLoggedIn) {
      const loginTime = new Date() - new Date(store.state.auth.dateLogin)
      // отправляем запрос для проверки авторизации каждые 120 минут (7200000)
      if (loginTime > 7200000) {
        store.dispatch('auth/fetch')
      }
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    if (store.state.auth.isLoggedIn) {
      next({ name: 'Home' })
    }
    next()
  }
})

router.afterEach(() => {
  store.dispatch('showMenu')
})

export default router
