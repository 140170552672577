import apiClient from '../../api/apiClient'

export default {
  namespaced: true,

  state: {
  },

  mutations: {
  },

  actions: {
    // eslint-disable-next-line no-empty-pattern
    checkClientPhone ({}, phone) {
      return apiClient().post('register-client', { phone })
    }
  }
}
