import axios from 'axios'

export default () => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_CLIENT_DOMAIN + 'client/',
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.VUE_APP_CLIENT_BEARER}`
    }
  })
}
