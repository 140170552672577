import apiClient from '@/api/apiClient'

export default {
  namespaced: true,

  state: {
  },

  mutations: {
  },

  actions: {
    fetch ({ commit, state, dispatch }, filter) {
      return new Promise((resolve) => {
        apiClient().get('spa/services', {
          params: filter
        }).finally(() => {
          commit('TOGGLE_PRELOADER', false, { root: true })
        }).then(({ data }) => {
          resolve(data.data)
        }).catch((error) => {
          dispatch('checkUnauthenticated', error, { root: true })
        })
      })
    }
  }
}
